export const serviceArea = [
     {
        name: "Ballarat Central",
        Suburb: "Ballarat-Central",
        hide: true,
      },
     {
        name: "Ballarat",
        Suburb: "Ballarat",
        hide: false,
      },
     {
        name: "Lake Wendouree",
        Suburb: "Lake-Wendouree",
        hide: true,
      },
     {
        name: "Ballarat East",
        Suburb: "Ballarat-East",
        hide: true,
      },
     {
        name: "Soldiers Hill",
        Suburb: "Soldiers-Hill",
        hide: true,
      },
     {
        name: "Redan",
        Suburb: "Redan",
        hide: true,
      },
     {
        name: "Black Hill",
        Suburb: "Black-Hill",
        hide: false,
      },
     {
        name: "Golden Point",
        Suburb: "Golden-Point",
        hide: true,
      },
     {
        name: "Newington",
        Suburb: "Newington",
        hide: true,
      },
     {
        name: "Sebastopol",
        Suburb: "Sebastopol",
        hide: true,
      },
     {
        name: "Mount Pleasant",
        Suburb: "Mount-Pleasant",
        hide: true,
      },
     {
        name: "Ballarat North",
        Suburb: "Ballarat-North",
        hide: true,
      },
     {
        name: "Canadian",
        Suburb: "Canadian",
        hide: true,
      },
     {
        name: "Eureka",
        Suburb: "Eureka",
        hide: true,
      },
     {
        name: "Alfredton",
        Suburb: "Alfredton",
        hide: false,
      },
     {
        name: "Nerrina",
        Suburb: "Nerrina",
        hide: true,
      },
     {
        name: "Lake Gardens",
        Suburb: "Lake-Gardens",
        hide: true,
      },
     {
        name: "Invermay Park",
        Suburb: "Invermay-Park",
        hide: true,
      },
     {
        name: "Wendouree",
        Suburb: "Wendouree",
        hide: false,
      },
     {
        name: "Delacombe",
        Suburb: "Delacombe",
        hide: true,
      },
     {
        name: "Mount Clear",
        Suburb: "Mount-Clear",
        hide: true,
      },
     {
        name: "Invermay",
        Suburb: "Invermay",
        hide: true,
      },
     {
        name: "Brown Hill",
        Suburb: "Brown-Hill",
        hide: true,
      },
     {
        name: "Lucas",
        Suburb: "Lucas",
        hide: true,
      },
     {
        name: "Smythes Creek",
        Suburb: "Smythes-Creek",
        hide: true,
      },
     {
        name: "Warrenheip",
        Suburb: "Warrenheip",
        hide: true,
      },
     {
        name: "Mount Helen",
        Suburb: "Mount-Helen",
        hide: true,
      },
     {
        name: "Mitchell Park",
        Suburb: "Mitchell-Park",
        hide: true,
      },
     {
        name: "Cardigan",
        Suburb: "Cardigan",
        hide: true,
      },
     {
        name: "Miners Rest",
        Suburb: "Miners-Rest",
        hide: true,
      },
     {
        name: "Buninyong",
        Suburb: "Buninyong",
        hide: true,
      },
     {
        name: "Napoleons",
        Suburb: "Napoleons",
        hide: true,
      },
     {
        name: "Haddon",
        Suburb: "Haddon",
        hide: true,
      },
     {
        name: "Cardigan Village",
        Suburb: "Cardigan-Village",
        hide: true,
      },
     {
        name: "Ross Creek",
        Suburb: "Ross-Creek",
        hide: true,
      },
     {
        name: "Creswick",
        Suburb: "Creswick",
        hide: true,
      },
     {
        name: "Smythesdale",
        Suburb: "Smythesdale",
        hide: true,
      },
     {
        name: "Enfield",
        Suburb: "Enfield",
        hide: true,
      },
];
