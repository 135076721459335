export const openHours = [
    {
        Day: 'Tuesday & Thursday',
        StartTime: '5pm',
        EndTime: '9pm'
    },
    {
        Day: 'Saturday',
        StartTime: '10am',
        EndTime: '4pm'
    },
]
